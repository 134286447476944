import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../layouts/main/Layout';
import BlogRoll from '../components/BlogRoll/BlogRoll';
import { SiteContext } from '../context/SiteContextProvider';
import SEO from '../util/SEO/SEO';

const blog = ({ ...props }) => {
  const intl = useIntl();


  const { edges: posts } = props.data.blogPosts;
  const { originalPath } = props.pathContext.intl;

  return (
    <Layout originalPath={originalPath}>
      <SEO
        locale={intl.locale}
        title={intl.formatMessage({ id: 'blog.SEOtitle' })}
        description={intl.formatMessage({ id: 'blog.SEODescription' })}
        keywords={intl.formatMessage({ id: 'SEOkeywords.keywords' })}
      />
      <SiteContext.Consumer>
        {(context) => (
          <BlogRoll posts={posts.filter((post) => post.node.frontmatter.locale === context.data.locale)} lang={context.data.locale} />
        )}
      </SiteContext.Consumer>

    </Layout>
  );
};


blog.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }).isRequired,
};


export default blog;

export const pageQuery = graphql`
  query BlogContent($locale: String) {
    blogPosts: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageKey: { eq: "page_blogpost" }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            date
            slug
            locale
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
