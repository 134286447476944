import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const BlogRoll = ({ posts, lang }) => {
  console.log(lang);
  const link = lang === 'pl' ? `/${lang}/blog/` : '/blog/';

  return (
    <>
      {posts.map(({ node }) => (
        <div key={node.frontmatter.title} className="blog-intro">
          {node.frontmatter.featuredimage

          && (
          <figure className="blog-figure">
            <Img fluid={node.frontmatter.featuredimage.childImageSharp.fluid} />
          </figure>
          )}
          <article className="blog-details">
            <h3>
              {node.frontmatter.title}
            </h3>
            <p className="post-text-preview">
              {node.frontmatter.description}
            </p>
            <Link
              className="btn btn-primary"
              to={`${link}${node.frontmatter.slug}`}
              title="link to blog post"
            >
              Read more
            </Link>
          </article>
        </div>
      ))}
    </>
  );
};

BlogRoll.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.shape({
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired,
        }).isRequired,
        fields: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
  ).isRequired,
};

export default BlogRoll;
